import { GET, POST, PUT, OutUrl, ApiUrl } from '@/config/index'

// 兴趣标签
const getLikeList = GET(`${OutUrl}/api/backapp/technology/system/open/action/interest/allList`)
// 获取我的兴趣标签
const getMyLikeList = (id) => {
  return GET(`${OutUrl}/api/backapp/technology/system/open/action/interest/${id}`)()
} 
// 创建兴趣标签
const createLike = POST(`${OutUrl}/api/backapp/technology/system/open/action/interest/create`)
// 修改兴趣标签
const editLike = PUT(`${OutUrl}/api/backapp/technology/system/open/action/interest/edit`)
//农户登录二次校验
const sendUserInfo = POST(`${ApiUrl}/api/backapp/technology-web/farmer/mapping/`)

const expertInterest = (id) => { return GET(`${ApiUrl}/gateway/api/backapp/technology/system/open/action/expertInterest/${id}`)() }

export default {
    getLikeList,
    getMyLikeList,
    createLike,
    editLike,
    expertInterest,
    sendUserInfo
}