<template>
  <div class="box">
    <div class="heard">
      <h3>平台服务协议</h3>
    </div>
    <div class="content">
      <div>
        张北县种薯农技服务平台依据以下条件和条款为您提供所享有的服务,请仔细阅读并遵守。
      </div>
      <div style="margin-top: 20px">
        欢迎阅读张北县种薯农技服务平台“平台服务协议”(下称“本协议”)。本协议阐述之条款和条件适用于您使用张北县种薯农技服务平台(所涉域名为http://portal.szprod.iot-farm.cn/,及张北县种薯农技服务平台未来或现在开设的手机客户端、无线端载体等)所提供的全面网上服务,该平台提供的服务包括但不限于现货销售、线上采购、金融协同等服务(下称“服务”)。
      </div>
      <div class="item">
        <div class="title">一、接受条款</div>
        <div class="font">
          1、本协议内容包括协议正文及所有张北县种薯农技服务平台已经发布或将来可能发布的各类规则、细则、协议等各类文本等。所有规则等均为本协议不可分割的一部分,与协议正文具有同等法律效力。
        </div>
        <div class="font">
          2、注册时点选接受“平台服务协议”或以任何方式进入张北县种薯农技服务平台(下称“平台”)网站并使用服务即表示您已充分阅读、理解并同意接受本协议的条款和条件(以下合称“条款”),并承诺按照条款的约定及平台的程序设置等享受权利、履行义务
        </div>
        <div class="font">
          3、张北县种薯农技服务平台有权根据业务需要酌情修订“条款”,并以公告的形式进行更新,不再单独通知予您。经修订的“条款”一经在网站公布,即产生效力。如您不同意相关修订,请立即停止使用“服务”,并于“条款”修改公布之日起7日内以书面的形式按平台公布的联系方式提出异议。如您继续使用“服务”,则将视为您已接受经修订的“条款”,当您与平台发生争议时,应以最新的“条款”为准
        </div>
      </div>
      <div class="item">
        <div class="title">二、注册</div>
        <div class="font">
          您确认,在您完成注册程序或以张北县种薯农技服务平台允许的其他方式实际使用服务时,您应当是具备完全民事权利能力和与所从事的民事行为相适应的行为能力的自然人、法人或其他组织。若您不具备前述主体资格,请勿使用服务,否则您及您的监护人应承担因此而导致的一切后果,且张北县种薯农技服务平台有权注销(永久冻结)您的账户,并向您及您的监护人索偿。如您是一家公司或其他法律主体在平台登记,则您须声明和保证,您已授权相应的自然人在平台上进行本平台业务范围内的操作,该自然人在平台上所形成的一切法律后果及责任均由您承担。同时,您明确知悉,因您在平台中接受服务的不同,您可能会因非法人主体的身份而无法进行相应服务的提供或接受。
        </div>
        <div class="font">
          2、注册账户
          2.1在您按照注册页面提示填写信息、阅读并接受本协议且完成全部注册程序后,或在您通过链接按照相关页面提示填写信息、阅读并接受本协议且完成全部激活程序后,或您以张北县种薯农技服务平台允许的其他方式实际使用平台服务时,您即受本协议的约束。您可以使用您提供或确认的登录名和密码登录平台。
          2.2在完成注册或激活流程时,您应当按照法律法规要求,按相应页面的提示准确提供并及时更新您的资料,以使之真实、及时、完整和准确。如平台有合理理由怀疑您提供的资料错误、不实、过时或不完整的,张北县种薯农技服务平台有权向您发出询问及/或要求改正的通知,并有权直接做出删除相应资料的处理,直至中止、终止对您提供部分或全部的平台服务(含平台合作方的服务),张北县种薯农技服务平台对此不承担任何责任,您将承担因此产生的任何直接损失、间接损失及不利后果
        </div>
      </div>
      <div class="item">
        <div class="title">三、账户安全</div>
        <div class="font">
          您须自行负责对您的平台登录名、昵称和密码保密,且须对该登录名、昵称和密码下发生的所有活动(包括但不限于信息披露、发布信息、网上点击同意或提交各类规则协议、网上续签协议或购买服务等)承担责任。您同意:(a)如发现任何人未经授权使用您的登录名、昵称、密码,或资金账户登录信息,或发生违反保密规定的任何其他情况,您会立即通知张北县种薯农技服务平台,并授权张北县种薯农技服务平台将上述信息可能被窃取等事宜同步给合作方网站、网商银行或其他合作第三方;及(b)确保您在每个上网时段结束时,以正确步骤离开网站。张北县种薯农技服务平台不能也不会对因您未能遵守本款规定而发生的任何损失或损毁负责。您理解张北县种薯农技服务平台对您的请求采取行动需要合理时间,平台对在采取行动前已经产生的后果(包括但不限于您的任何损失)不承担任何责任。除非有法律规定或司法裁定,且征得平台的同意,否则,您的登录名、昵称、密码以及资金账户登录信息不得以任何方式转让、赠与或继承(与账户相关的财产权益除外)。
        </div>
      </div>
      <div class="item">
        <div class="title">四、服务终止</div>
        <div class="font">
          1、服务终止:
          1.1您同意,在张北县种薯农技服务平台未向您收费的情况下,张北县种薯农技服务平台可自行全权决定以任何理由(包括但不限于平台认为您已违反本协议的字面意义和精神,或您以不符合本协议的字面意义和精神的方式行事)终止您的“服务”密码、账户(或其任何部分)或您对“服务”的使用。您同意,在张北县种薯农技服务平台向您收费的情况下,张北县种薯农技服务平台应基于合理的怀疑且经电子邮件等形式通知的情况下实施上述终止服务的行为。您进一步承认和同意,张北县种薯农技服务平台根据本协议规定终止您服务的情况下,可立即使您的账户无效,或注销您的账户以及在您的账户内的所有相关资料和档案,和/或禁止您进一步接入该等档案或“服务”。账户终止后,平台没有义务为您或第三方转发任何您未曾阅读或发送的信息。此外,您同意,张北县种薯农技服务平台不会就终止向您提供“服务”而对您或任何第三者承担任何责任。
          1.2您有权向张北县种薯农技服务平台要求注销您的账户,经平台审核同意的,将注销您的账户,届时,您与平台基于本协议的合同关系即终止。您的账户被注销后,平台没有义务向您披露您账户中的任何信息,也没有义务向您或第三方转发任何您未曾阅读或发送过的信息。
          1.3您理解并同意,您与张北县种薯农技服务平台的合同关系终止后:
          a)平台有权继续保存您的资料。
          b)您在使用服务期间存在违法行为或违反本协议和/或规则的行为的,张北县种薯农技服务平台仍可依据本协议向您主张权利。
          c)您在使用服务期间因使用服务与其他用户之间发生的关系,不因本协议的终止而终止,其他用户仍有权向您主张权利,您应继续按您的承诺履行义务
        </div>
      </div>
      <div class="item">
        <div class="title">五、关于费用</div>
        <div class="font">
          张北县种薯农技服务平台保留在根据第一条第3款通知您后收取“服务”费用的权利。另外,您因进行交易、向平台(含平台合作方)获取有偿服务或其他平台服务而发生的所有应纳税赋,以及相关硬件、软件、通讯、网络服务及其他方面的费用均由您自行承担。平台保留对未收费项目改为收费项目的权利。平台在某时期内对某项目的免费并不表明平台对该项目永久免费。平台保留在无须发出书面通知,仅在网站公示的情况下,暂时或永久地更改或停止部分或全部“服务”的权利
        </div>
      </div>
      <div class="item">
        <div class="title">六、张北县种薯农技服务平台服务和地位</div>
        <div class="font">
          1、通过张北县种薯农技服务平台(含平台合作方)提供的服务,您可在平台网站上发布交易信息、查询商品和服务信息、达成交易意向并进行交易、对其他会员进行评价、参加平台组织的活动以及使用其它信息服务及技术服务。同时,为了给您提供更加贴心和个性化的服务,平台将会向您所填写的电话号码发送系统消息、交易信息、网站内容推送、资金变化等内容,并用于拨打或者接听语音通话,您对本服务条款的认可,表示您同意接收上述信息,如您不同意接收上述信息的,您可发送TD退订网站推送短信,也可拨打客服电话退订。平台还可能通过使用用户信息,分析您可能感兴趣的信息并向您提供;或通过系统向您展示个性化的第三方推广信息;与合作伙伴及其他会员共享您的信息,帮助您获得最便捷的交易体验。
          2、平台网站仅作为用户选择交易对象,就货物和服务的交易进行协商,以及获取各类与贸易相关的服务的地点。同时,张北县种薯农技服务平台不涉及会员间因交易而产生的法律关系及法律纠纷,不会且不能牵涉进交易各方的交易当中。敬请注意,张北县种薯农技服务平台不能控制或保证商贸信息的真实性、合法性、准确性,亦不能控制或保证交易所涉及物品的质量、安全或合法性,以及相关交易各方履行在贸易协议项下的各项义务的能力。张北县种薯农技服务平台不能也不会控制交易各方能否履行协议义务。此外,您应注意到,与以欺诈手段行事的相对方进行交易的风险是客观存在的。平台希望您在使用张北县种薯农技服务平台网站(含网商银行)服务时,小心谨慎并运用常识。
          3、张北县种薯农技服务平台与阿里巴巴网站、以及网商银行为合作关系,网商银行与阿里巴巴为张北县种薯农技服务平台合作方(下称“平台合作方”)。网商银行为张北县种薯农技服务平台会员提供线上支付、结算等资金账户管理服务,会员将在线上与网商银行另行签署协议约定相关的权利义务。如上述服务出现问题,平台会员应穷尽一切可能及时联络平台及平台合作方,平台将协助会员与阿里巴巴和网商银行进行沟通协调。
        </div>
      </div>
      <div class="item">
        <div class="title">七、服务使用规范</div>
        <div class="font">
          1、关于您的资料的规则
          1.1“您的资料”包括您在注册、发布信息或交易等过程中、在任何公开信息场合或通过任何电子邮件形式,向张北县种薯农技服务平台(含平台合作方)或其他会员提供的任何资料,包括数据、文本、软件、音乐、声响、照片、图画、影像、词句或其他材料。您应对“您的资料”负全部责任,而平台仅作为您在网上发布和刊登“您的资料”的被动接收渠道。
          1.2您同意并承诺,“您的资料”和您在张北县种薯农技服务平台(含平台合作方)上交易的任何物品:
          a.不会有欺诈成份,与售卖伪造或盗窃无涉;
          b.不会侵犯任何第三者对该物品享有的物权,或版权、专利、商标、商业秘密或其他知识产权;
          c.不会违反任何法律、法规、条例或规章(包括但不限于关于规范出口管理、凭许可证经营、贸易配额、保护消费者、不正当竞争或虚假广告的法律、法规、条例或规章)、本协议及相关规则;
          d.不会含有诽谤(包括商业诽谤)、非法恐吓或非法骚扰的内容;
          e.不会含有淫秽、或包含任何儿童色情内容;
          f.不会含有蓄意毁坏、恶意干扰、秘密地截取或侵占任何系统、数据或个人资料的任何病毒、伪装破坏程序、电脑蠕虫、定时程序炸弹或其他电脑程序;
          g.不会直接或间接与下述各项货物或服务连接,或包含对下述各项货物或服务的描述:(i)本协议项下禁止的货物或服务;或(ii)您无权连接或包含的货物或服务。此外,您同意不会:(ⅲ)在与任何连锁信件、大量胡乱邮寄的电子邮件、滥发电子邮件或任何复制或多余的信息有关的方面使用“服务”;(ⅳ)未经其他人士同意,利用“服务”收集其他人士的电子邮件地址及其他资料;(ⅴ)利用“服务”制作虚假的电子邮件地址,或以其他形式试图在发送人的身份或信息的来源方面误导其他人士;
          h.不含有张北县种薯农技服务平台(含平台合作方)认为应禁止或不适合通过平台宣传或交易。
          1.3您同意,您不会对任何资料作商业性利用,包括但不限于在未经张北县种薯农技服务平台(含平台合作方)事先书面批准的情况下,复制在平台上展示的任何资料并用于商业用途。
          ２、违反规则的后果。
          2.1倘若张北县种薯农技服务平台认为“您的资料”可能使平台承担任何法律或道义上的责任，或可能使张北县种薯农技服务平台（全部或部分地）失去平台的互联网服务供应商或其他供应商的服务，则平台可自行全权决定对“您的资料”采取平台认为必要或适当的任何行动，包括但不限于删除该类资料。您特此保证，您对提交给平台的“您的资料”拥有全部权利，包括全部版权。您确认，平台没有责任去认定或决定您提交给平台的资料哪些是应当受到保护的，对享有“服务”的其他用户使用“您的资料”，张北县种薯农技服务平台也不必承担责任。
          2.2对于您涉嫌违反承诺的行为对任意第三方造成损害的，您均应当以自己的名义独立承担所有的法律责任，并应确保张北县种薯农技服务平台免责。
          2.3在不限制其他补救措施的前提下，发生下述任一情况，张北县种薯农技服务平台可立即发出警告，暂时中止、永久中止或终止您的会员资格，删除您的任何现有产品信息，以及您在网站上展示的任何其他资料：(i)您违反本协议；(ii)张北县种薯农技服务平台无法核实或鉴定您向平台提供的任何资料；(iii)平台相信您的行为可能会使您、平台会员、平台合作方或通过张北县种薯农技服务平台网站提供服务的第三者服务供应商发生任何法律责任。在不限制任何其他补救措施的前提下，若发现您从事涉及平台网站（含平台合作方网站）的诈骗活动，张北县种薯农技服务平台可暂停或终止您的账户。
          2.4经生效法律文书确认用户存在违法或违反本协议行为或者张北县种薯农技服务平台自行判断用户涉嫌存在违法或违反本协议行为的，平台有权以网络发布形式公布用户的违法行为并做出处罚（包括但不限于限权、终止服务等）。
        </div>
      </div>
      <div class="item">
        <div class="title">八、您授予的许可使用权</div>
        <div class="font">
          您完全理解并同意不可撤销地授予张北县种薯农技服务平台及其平台合作方下列权利:
          1、对于您提供的资料,您授予张北县种薯农技服务平台及其平台合作方全球通用的、永久的、免费的许可使用权利(并有权在多个层面对该权利进行再授权),使张北县种薯农技服务平台及其平台合作方有权(全部或部分地)使用、复制、修订、改写、发布、翻译、分发、执行和展示“您的资料”或制作其派生作品,和/或以现在已知或日后开发的任何形式、媒体或技术,将“您的资料”纳入其他作品内。
          2、当您违反本协议或与平台合作方签订的其他协议的约定,张北县种薯农技服务平台及其平台合作方有权以任何方式通知平台的关联公司,要求其对您的权益采取限制措施,要求关联公司中止、终止对您提供部分或全部服务,且在其经营或实际控制的任何网站公示您的违约情况。
          3、同样,当您向平台的关联公司作出任何形式的承诺,且相关公司已确认您违反了该承诺,则张北县种薯农技服务平台及其平台合作方有权立即按您的承诺约定的方式对您的账户采取限制措施,包括但不限于中止或终止向您提供服务,并公示相关方确认的您的违约情况。您了解并同意,张北县种薯农技服务平台及其平台合作方无须就相关确认与您核对事实,或另行征得您的同意,且张北县种薯农技服务平台无须就此限制措施或公示行为向您承担任何的责任。
        </div>
      </div>
      <div class="item">
        <div class="title">九、隐私</div>
        <div class="font">
          尽管有第八条所规定的许可使用权,但基于保护您的隐私是张北县种薯农技服务平台的一项基本原则,为此平台还将根据网站公布的隐私声明使用“您的资料”。隐私声明的全部条款属于本协议的一部分,因此,您必须仔细阅读。请注意,您一旦自愿地在张北县种薯农技服务平台交易地点披露“您的资料”,该等资料即可能被其他人士获取和使用。
        </div>
      </div>
      <div class="item">
        <div class="title">十、责任范围和责任限制</div>
        <div class="font">
          1、您明确理解和同意,张北县种薯农技服务平台不对因下述任一情况而发生的任何损害赔偿承担责任,包括但不限于利润、商誉、使用、数据等方面的损失或其他无形损失的损害赔偿(无论平台是否已被告知该等损害赔偿的可能性):(i)使用或未能使用“服务”;(ii)因通过或从“服务”购买或获取任何货物、样品、数据、资料或服务,或通过或从“服务”接收任何信息或缔结任何交易所产生的获取替代货物和服务的费用;(iii)未经批准接入或更改您的传输资料或数据;(iv)任何第三者对“服务”的声明或关于“服务”的行为;或(v)因任何原因而引起的与“服务”有关的任何其他事宜,包括疏忽。
          2、张北县种薯农技服务平台会尽一切努力使您在使用平台网站的过程中得到乐趣。遗憾的是,平台不能随时预见到任何技术上的问题或其他困难,该等困难可能会导致数据损失或其他服务中断。为此,您明确理解和同意,您使用“服务”的风险由您自行承担,且“服务”以“按现状”和“按可得到”的状态提供。张北县种薯农技服务平台明确声明不作任何种类的明示或暗示的保证,包括但不限于关于适销性、适用于某一特定用途和无侵权行为等方面的保证。平台对下述内容不作保证:(i)“服务”会符合您的要求;(ii)“服务”不会中断,且适时、安全和不带任何错误;(iii)通过使用“服务”而可能获取的结果将是准确或可信赖的;及(iv)您通过“服务”而购买或获取的任何产品、服务、资料或其他材料的质量将符合您的预期。通过使用“服务”而下载或以其他形式获取任何材料是由您自行全权决定进行的,且与此有关的风险由您自行承担,对于因您下载任何该等材料而发生的您的电脑系统的任何损毁或任何数据损失,您将自行承担责任。您从张北县种薯农技服务平台或通过或从“服务”获取的任何口头或书面意见或资料,均不产生未在本协议内明确载明的任何保证责任。
        </div>
      </div>
      <div class="item">
        <div class="title">十一、赔偿</div>
        <div class="font">
          您同意,如因您违反本协议或经在此提及而纳入本协议的其他文件,或因您违反法律侵害了平台合作方及其他第三方的合法权利,或因您违反法律须承担行政或刑事责任,而使第三方或行政、司法机关对张北县种薯农技服务平台及其子公司、合作方(阿里巴巴和网商银行)、关联公司、分公司、董事、职员、代理人提出索赔或处罚要求(包括司法费用和其他专业人士的费用),您必须全额赔偿给张北县种薯农技服务平台及其子公司、合作方、关联公司、分公司、董事、职员、代理人,并使其等免遭损失。
        </div>
      </div>
      <div class="item">
        <div class="title">十二、链接</div>
        <div class="font">
          张北县种薯农技服务平台可提供其他第三者网站或资源的链接。由于平台并不控制该等网站和资源,您承认并同意,张北县种薯农技服务平台并不对该等外在网站或资源的可用性负责,且不认可该等网站或资源上或可从该等网站或资源获取的任何内容、宣传、产品、服务或其他材料,也不对该等网站或资源上或可从该等网站或资源获取的任何内容、宣传、产品、服务或其他材料等负责或承担任何责任。您进一步承认和同意,对于任何因使用或信赖从此类网站或资源上获取的此类内容、宣传、产品、服务或其他材料而造成(或声称造成)的任何直接或间接损失,张北县种薯农技服务平台均不承担责任。
        </div>
      </div>
      <div class="item">
        <div class="title">十三、通知</div>
        <div class="font">
          1、您应当准确填写并及时更新您提供的电子邮件地址、联系电话、联系地址、邮政编码等联系方式,以便张北县种薯农技服务平台及平台合作方或其他会员或用户等与您进行有效联系,因通过这些联系方式无法与您取得联系,导致您在使用平台服务过程中产生任何损失或增加费用的,应由您完全独自承担。您了解并同意,您有义务保持你提供的联系方式的有效性,如有变更需要更新的,您应按平台的要求进行操作。
          2、如您需要和平台进行沟通,您可和平台客服联系。除此方式外,平台暂不接受您采取其他方式和平台联系。如平台有任何信息、内容、规则等需要发布的,平台将在相关页面发布公告
        </div>
      </div>
      <div class="item">
        <div class="title">十四、不可抗力</div>
        <div class="font">
          对于因张北县种薯农技服务平台及平台合作方合理控制范围以外的原因,包括但不限于自然灾害、罢工或骚乱、物质短缺或定量配给、暴动、战争行为、政府行为、通讯或其他设施故障或严重伤亡事故等,致使张北县种薯农技服务平台及平台合作方延迟或未能履约的,张北县种薯农技服务平台不对您承担任何责任。
        </div>
      </div>
      <div class="item">
        <div class="title">十五、法律适用、管辖及其他</div>
        <div class="font">
          1、本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国大陆地区法律,如无相关法律规定的,则应参照通用的商业惯例和(或)行业惯例。
          2、您认可张北县种薯农技服务平台发布的《平台总则》、《交易细则》、《隐私声明》、《知识产权声明》、《免责声明》属于本协议的一部分；您认可张北县种薯农技服务平台将来可能发布的各类规则、细则、协议等各类文本属于本协议的一部分；所有张北县种薯农技服务平台已经发布或将来可能发布的各类规则、细则、协议等文本均为本协议不可分割的一部分,与协议正文具有同等法律效力。
          3、您与张北县种薯农技服务平台仅为独立订约人关系。本协议无意结成或创设任何代理、合伙、合营、雇佣与被雇佣或特性授权与被授权关系。
          4、您同意张北县种薯农技服务平台因经营业务需要有权将本协议项下的权力义务就部分或全部进行转让,而无须再通知予您并取得您的同意。
          5、因本协议或张北县种薯农技服务平台的服务所引起或与其有关的任何争议应向五矿电子商务有限公司所在地有管辖权的人民法院提起诉讼。
          6、本协议取代您和张北县种薯农技服务平台(含平台合作方)先前就相同事项订立的任何书面或口头协议。倘若本协议任何条款被裁定为无效或不可强制执行,该项条款应被撤销,而其余条款应予遵守和执行。条款标题仅为方便参阅而设,并不以任何方式界定、限制、解释或描述该条款的范围或限度。张北县种薯农技服务平台未就您或其他人士的某项违约行为采取行动,并不表明张北县种薯农技服务平台撤回就任何继后或类似的违约事件采取行动的权利。
        </div>
      </div>
     <div class="backbox">
        <a-button type="primary" @click="clickback" class="btn" size="large"> 我已阅读 </a-button>
     </div>
    </div>
  </div>
</template>
<script>
export default {
  // name:'agreement'
  methods: {
    clickback() {
     history.back()
    },
  },
};
</script>
<style lang="less" scoped>
.box {
  overflow: scroll;
  z-index: 999;
  background-color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  .heard {
    width: 100%;
    text-align: center;
    line-height: 70px;
    h3 {
      font-weight: 500;
      padding: 0;
      font-size: 26px;
      font-family: 微软雅黑, Microsoft YaHei;
    }
  }
  .content {
    width: 1000px;
    margin: 0 auto;
    text-overflow: ellipsis;
    font-size: 14px;
    .item {
      .title {
        margin: 20px 0;
      }
      .font {
        margin-bottom: 15px;
      }
    }
    
    .backbox{
      height: 90px;
      width: 100%;
      text-align: center;
      .btn {
        min-width: 60px;
        min-height: 20px;
        margin-top: 20px;
      background-color: #1890ff;
      border-color: #1890ff;
    }
    }
  }
}
</style>