<template>
  <div id="main-app">
    <div
      v-if="$route.path !== '/login' && $route.path !== '/regist'"
      class="welcome"
    >
      <div class="welMsg" @click="goHome">{{ welcomeMsg }}</div>
      <div v-if="!isLogin" class="no-login">
        <div
          @click="
            () => {
              $router.push('/login');
            }
          "
        >
          你好，请登录
        </div>
        <!-- <a-dropdown class="login-to">
          <a class="ant-dropdown-link" @click="login">
            系统登录入口<a-icon :type="!arrowType ? 'down' : 'up'" />
          </a>
          <a-menu slot="overlay">
            <template v-for="(item, idx) in platforms">
              <a-menu-item :key="idx">
                <a href="javascript:;">{{ item.name }}</a>
              </a-menu-item>
            </template>
          </a-menu>
        </a-dropdown> -->
      </div>
      <div v-else class="login-to">
        <div>
          <a-dropdown style="color: unset">
            <a class="ant-dropdown-link" @click="goPersonalCenter">
              个人中心 
            </a>
            <!-- <a-menu slot="overlay">
              <a-menu-item v-for="(item, index) in dropdownMenu" :key="index">
                <a
                  href="javascript:;"
                  @click="$router.push({ path: item.path })"
                  >{{ item.title }}</a
                >
              </a-menu-item>
            </a-menu> -->
          </a-dropdown>
        </div>
        <!-- <a-divider type="vertical" /> -->
        <div>
          <img :src="userIcon" style="width: 24px" />
          {{ userInfo.userName }}
        </div>
        <!-- <a-divider type="vertical" /> -->
        <!-- <div>
          <a-dropdown style="color: unset">
            <a class="ant-dropdown-link" @click="goPersonalCenter">
              消息 <a-icon type="down" />
            </a>
            <a-menu slot="overlay">
              <a-menu-item>
                <a href="javascript:;">系统</a>
              </a-menu-item>
              <a-menu-item>
                <a href="javascript:;">求助</a>
              </a-menu-item>
              <a-menu-item>
                <a href="javascript:;">直播</a>
              </a-menu-item>
              <a-menu-item>
                <a href="javascript:;">新活动</a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div> -->
        <!-- <a-divider type="vertical" /> -->
        <div style="margin-right: 0" @click="logout">
          <img :src="logoutImg" style="width: 14px" />
          退出登录
        </div>
      </div>
    </div>
    <a-config-provider :locale="locale">
      <transition :name="transitionName">
        <keep-alive>
          <router-view v-if="isRouterAlive" />
        </keep-alive>
      </transition>
    </a-config-provider>
    <template v-if="$route.path !== '/login' && $route.path !== '/regist'">
      <div class="friends">
        <span>友情链接:</span>
        <span
          style="color: #ffffff"
          v-for="(friend, index) in friends"
          :key="index"
          @click="openUrl(friend.url)"
          >{{ friend.name }}</span
        >
      </div>
      <div class="about">
        <span>关于我们</span>
        <span>@Copyright2021，Inc.All Rights Reserved</span>
      </div>
      <!-- <div class="home-footer">
        <div class="about">
          <div class="title">
          <img style="width: 48px;height: 48px;" :src="logo"/>
          {{ platformName }}
        </div>
          <span class="copyRight">@Copyright2021，Inc.All Rights Reserved</span>
          <span class="beian">备案号：XXXXXX</span>
        </div>
        <div class="friends">
          <div class="friendsTitle">友情链接：</div>
          <div class="friendsContainer">
            <template v-for="(friend, index) in Math.ceil(friends.length / 3)">
              <div :key="index" class="friendsLink">
                <a v-if="friends[3*index]" :href="friends[3*index].url" target="_blank">{{
                  friends[3*index].name
                }}</a>
                <a v-if="friends[3*index + 1]" :href="friends[3*index + 1].url" target="_blank">{{
                  friends[3*index + 1].name
                }}</a>
                <a v-if="friends[3*index + 2]" :href="friends[3*index + 2].url" target="_blank">{{
                  friends[3*index + 2].name
                }}</a>
              </div>
            </template>
          </div>
        </div>
        <div class="qr-code">
          <img :src="qrcode"/>
          <div>
            <div>扫一扫</div>
            <div>进入手机版</div>
          </div>
        </div>
      </div> -->
    </template>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import store from "@/store";
import { mapGetters } from "vuex";

export default {
  name: "App",
  components: {},
  data() {
    return {
      isRouterAlive: true,
      locale: zhCN,
      welcomeMsg: "欢迎来到张北县种薯农技服务平台",
      platformName: "张北县种薯农技服务平台",
      logo: require("@/assets/img/suzhou_logo.png"),
      qrcode: require("@/assets/img/miniapp_code.jpg"),
      arrowType: false,
      platforms: [
        // 平台s
        {
          name: "产业服务平台运营后台",
        },
        {
          name: "区块链溯源系统",
        },
        {
          name: "物联网系统",
        },
      ],
      friends: [],
      logoutImg: require("@/assets/img/logout.png"),
      userIcon: require("@/assets/img/userIcon.png"),
      transitionName: "",
      dropdownMenu: [
        //下拉菜单项
        {
          title: "留言板",
          path: "/personalCenter/messageBoard",
        },
        {
          title: "我的收藏",
          path: "/personalCenter/supplyCollection",
        },
        // {
        //   title: "供应管理",
        //   path: "/personalCenter/mySupply",
        // },
        // {
        //   title: "需求管理",
        //   path: "/personalCenter/myNeeds",
        // },
        // {
        //   title: "订单管理",
        //   path: "/personalCenter/demandOrder",
        // },
        {
          title: "农技学堂",
          path: "/myNjkt/myExperts",
        },
        // {
        //   title: "农机预约",
        //   path: "/personalCenter/myReservation",
        // }
      ],
    };
  },
  watch: {
    $route(to, from) {
      const toDepth = to.path.split("/").length;
      const fromDepth = from.path.split("/").length;
      this.transitionName = toDepth < fromDepth ? "slide-right" : "slide-left";
    },
  },
  computed: {
    ...mapGetters(["isLogin"]),
    ...mapGetters(["userInfo"]),
  },
  created () {
    console.log('App created');
    const userInfo = sessionStorage.getItem('userInfo')
    if (userInfo) {
      store.commit("setUserInfo", JSON.parse(userInfo));
    }
    this.getMenus();
    this.getFriends();
  },
  mounted() {},
  methods: {
    // route-view刷新
    getFriends() {
      const param = {
        body: {
          type: 3, //1：广告管理 2菜单管理 3友情链接 4：合作伙伴
          orders: "+sort",
        },
      };
      this.$axios.homeQueryList(param).then((res) => {
        if (res.data.body) {
          this.friends = [...res.data.body];
          console.log(this.friends);
        } else {
          this.friends = {
            moreUrl: "",
            pageResultDetail: {
              dataList: [],
            },
          };
        }
      });
    },
    getMenus() {
      const params = {
        body: {
          type: 2, //1：广告管理 2菜单管理 3友情链接 4：合作伙伴
          termType: 1,
          orders: "+sort",
        },
      };
      this.$axios.homeQueryList(params).then((res) => {
        if (res.data.body) {
          const menus = res.data.body.sort(
            (a, b) => parseFloat(b.sort) - parseFloat(a.sort)
          );
          store.commit("setMenus", menus);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
        this.$router.push("/myNjkt/myMsgs");
      });
    },
    login() {
      this.arrowType = !this.arrowType;
      // this.$loginModal()
      this.$router.push("/login");
    },
    // 退出登录
    logout() {
      try {
        this.$axios.loginOut().then((res) => {
          console.log('logout', res);
          if (res.data.code === '0') {
            sessionStorage.removeItem('userInfo')
            Cookies.remove('accessToken')
            store.commit('removeUserInfo')
            store.commit('removeRoles')
            this.$router.push('/login')
          } else {
            this.$utils.errorMsg(res.data.msg)
          }
        }).catch((e) => {
          console.log('logout error', e);
        })
      } catch (error) {
        this.$utils.errorMsg(error);
      }
    },
    goPersonalCenter() {
      if (this.isLogin) {
        if (this.$route.path.indexOf("personalCenter") > -1) {
          this.reload();
        } else {
          this.$router.push("/myNjkt/myMsgs");
        }
      } else {
        this.$router.push("/login");
      }
    },
    goHome() {
      this.$router.push("/njkt/");
    },
    openUrl(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="less">
#main-app {
  font-family: PingFangSC-Regular, PingFang SC;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100vh;
  padding-top: 40px;
  display: flex;
  flex-direction: column;

  .welcome {
    width: 100%;
    z-index: 99;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    text-align: center;
    height: 40px;
    background: #f7f7f7;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 40px;
    .no-login {
      display: flex;
      cursor: pointer;
      div {
        padding-right: 30px;
      }
    }
    .welMsg {
      cursor: pointer;
      float: left;
      margin-left: 136px;
    }
    .login-to {
      display: flex;
      color: inherit;
      align-items: center;
      margin-right: 136px;
      cursor: pointer;
      div {
        margin-right: 20px;
      }
    }
  }

  .friends {
    margin-top: 10px;
    padding-top: 24px;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    background: @primary-color;
    line-height: 20px;
    span {
      cursor: pointer;
      margin-left: 22px;
    }
    a {
      margin-left: 22px;
    }
  }
  .about {
    padding-top: 20px;
    padding-bottom: 17px;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    background: @primary-color;
    line-height: 20px;
    span {
      display: block;
    }
  }
  .amap-logo {
    display: none !important;
  }
}
body {
  margin: 0;
  padding: 0;
}

.el-rwo {
  height: 100%;
}
// 字典删除后隐藏
*[data-delete="1"] {
  display: none!important;
}
</style>
